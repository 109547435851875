@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

// Base
$breakpoint-xl: '1280px';
$breakpoint-lg: '1024px';
$breakpoint-md: '768px';
$breakpoint-sm: '640px';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
}

.no-scroll {
  overflow: hidden;
  padding-right: 17px;
}

.layout {
  width: 100%;
  max-width: 2400px;
  margin: 0 auto;
  box-sizing: border-box;
}

.section {
  padding: 80px;
  @media (max-width: $breakpoint-md) {
    padding: 50px;
  }
}

.bg-black {
  background-color: black;
  color: white;
}

a {
  display: block;
  text-decoration: none;
  color: black;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 26px;
  color: rgb(52, 52, 52);
  margin: 0;
}

h1 {
  line-height: 60px !important;
  @media (max-width: $breakpoint-lg) {
    line-height: 42px !important;
  }
}

h2 {
  margin-top: 0;
  font-size: 36px;
  line-height: normal;
  @media (max-width: $breakpoint-lg) {
    font-size: 32px;
  }
}

li {
  letter-spacing: 1px;
  font-weight: 400 !important;
}

button {
  outline: none;
  border: none;
  font-family: 'Montserrat', sans-serif;
}

//Navbar
.hamburger {
  cursor: pointer;
  margin-top: 8px;
  span {
    width: 20px;
    display: block;
    height: 2px;
    margin-bottom: 4px;
    border-radius: 10px;
    background-color: rgb(0, 0, 0);
  }
  .middle {
    transition: opacity 0.15s ease-out;
  }
  .top,
  .bottom {
    transition: transform 0.15s ease-out;
  }
}

.hamburger--close {
  .top {
    transform: rotate(45deg) translate(4.5px, 0px);
  }
  .middle {
    opacity: 0;
  }
  .bottom {
    transform: rotate(-45deg) translate(8.5px, -4px);
  }
}

.navbar {
  @extend .layout;
  position: fixed;
  margin: 0 auto;
  z-index: 30;
  transition: top 0.25s ease-in;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px;
  a {
    font-weight: 500;
  }
  a.active {
    font-weight: 700;
  }
}

// Banner
.banner-img {
  background-image: url('../assets/about_banner-bg.svg');
  background-size: cover;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

// CTA
.cta {
  max-width: 100%;
  padding: 12px 2rem;
  font-size: 13px;
  font-weight: 600;
  width: max-content;
  cursor: pointer;
  text-transform: uppercase;
}

.cta--white {
  color: white;
  background-color: transparent;
  border: 2px solid white;
}

.cta--black {
  background-color: white;
  color: black;
  border: 2px solid black;
}

.cta--black-fill {
  background-color: black;
  color: white;
}

// Content Section
.content-section_img {
  background-position: 0;
}

.content-section_img_1 {
  background-image: url('../assets/about_1.png');
}
.content-section_img_2 {
  background-image: url('../assets/about_2.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  @media (max-width: 500px) {
    background-attachment: unset;
    background-position: 20%;
  }
}

// Careers
.careers {
  @extend .layout;
  @extend .section;
}

// Footer
.footer {
  @extend .layout;
  @extend .section;
  li,
  a,
  p,
  span {
    list-style: none;
    font-size: 14px;
    color: rgb(0, 0, 0);
  }
  p {
    width: 60px;
    display: block;
  }
  p,
  span {
    display: inline-block;
  }
  span {
    margin-left: 2rem;
    @media (max-width: $breakpoint-sm) {
      margin-left: 1.5rem;
    }
  }
}

//Stats
.stats {
  @extend .layout;
}

.stats_bg {
  background-image: url('../assets/stats_bg.png');
  background-position-x: 0;
}

//About
.about {
  @extend .layout;
  @extend .section;
}

.about_images {
  width: 45%;
  max-width: 700px;
  img {
    width: 100%;
  }
  @media (max-width: $breakpoint-xl) {
    width: 80%;
    img {
      margin-top: 20px;
    }
  }
}

//Team
.team {
  @extend .layout;
  @extend .section;
}

//Timeline
.timeline_container {
  @extend .layout;
  @extend .section;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
}

//Programs
.program_content {
  p,
  li {
    font-size: 14px;
  }
  li {
    font-weight: 300;
    line-height: 28px;
  }
  .cta {
    margin: 2rem auto 0 auto;
    text-align: center;
  }
}

.internship-img {
  width: 50%;
  height: inherit;
  background-image: url('../assets/internship.png');
  background-size: cover;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
}

.slick-dots {
  bottom: -40px !important;
}

//Scholarships
.scholarships_container {
  @extend .layout;
  @extend .section;
  padding-top: 0;
  background-color: white;
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  @media (max-width: $breakpoint-lg) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  @media (max-width: $breakpoint-md) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.scholarship_banner--charles_sauter {
  background-image: url('../assets/scholarships_charles-sauter.png');
}
.scholarship_banner--grace_fryer {
  background-image: url('../assets/scholarships_grace-fryer.png');
}
.scholarship_banner--henry_fried {
  background-image: url('../assets/scholarships_henry-fried.png');
}
.scholarship_banner--ben_banneker {
  background-image: url('../assets/scholarships_ben-banneker.png');
}
.scholarship_banner--oscar_waldan {
  background-image: url('../assets/scholarships_oscar-waldan.png');
}
.scholarship_banner--charles_london {
  background-image: url('../assets/scholarships_charles-london.png');
}

//Modal
.modal_bg {
  background-color: rgba(0, 0, 0, 0.753);
}

.modal {
  background-color: white;
  position: relative;
  border-radius: 5px;
  padding: 2rem;
  width: 600px;
  max-height: 70vh;
  overflow: auto;
  img {
    width: 50%;
    //height: fit-content;
    float: left;
    border-radius: 5px;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
  @media (max-width: $breakpoint-lg) {
    width: 70vw;
  }
  @media (max-width: $breakpoint-sm) {
    img {
      float: none;
      width: 100%;
    }
    .modal_content {
      text-align: center;
    }
  }
}

.close_container {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  svg {
    pointer-events: none;
  }
}

.home-accent {
  background-image: url('../assets/home-accent.png');
  background-size: cover;
  background-attachment: fixed;
  height: 140px;
  @extend .layout;
  @media (max-width: $breakpoint-sm) {
    display: none;
  }
}

.contact {
  @extend .layout;
  @extend.section;
  display: flex;
  justify-content: center;
  background-color: #f9fafc;
  .form-block {
    border: 1px solid #a5a8ad18;
    padding: 60px;
    background-color: white;
    margin-bottom: 40px;
  }
}

.form-control {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  label {
    width: 200px;
    font-size: 12px;
  }

  @media (max-width: $breakpoint-lg) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }
}

.form-group {
  display: flex;
  gap: 20px;
  .form-control,
  .form-spacer {
    flex-grow: 1;
    margin-bottom: 40px;
  }
}

input,
select,
textarea {
  width: calc(100% - 200px);
  font-size: 13px;
  height: 32px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #e5eaf1;
  background-color: #f9fafc;
  padding: 0 10px;
  box-sizing: border-box;
  &:focus {
    outline: 2px solid #e5eaf1;
    font-size: 13px !important;
  }
  @media (max-width: $breakpoint-lg) {
    width: 100%;
  }
}

.form-group--textarea {
  .form-control {
    align-items: start;
    justify-content: space-between;
    label {
      max-width: 300px;
      width: 300px;
      font-size: 12px;
      @media (max-width: $breakpoint-lg) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

textarea {
  min-width: calc(100% - 330px);
  width: calc(100% - 330px);
  resize: vertical;
  min-height: 180px;
  padding: 14px;
  @media (max-width: $breakpoint-lg) {
    width: 100%;
    max-width: 100%;
  }
}

input.w-50,
select.w-50 {
  width: calc(50% - 10px);
}

label {
  font-size: 13px;
  display: block;
  margin-bottom: 4px;
  color: #222;
}

.form-section_label {
  margin-bottom: 40px;
}

.form-error,
.error {
  color: red;
  font-size: 11px;
  margin-top: 2px;
  font-weight: 500;
  word-spacing: normal;
  letter-spacing: normal;
  line-height: 16px;
}

.form-error {
  width: 100%;
  text-align: right;
}

.error {
  margin-top: 10px;
}

option {
  font-size: 13px;
}

.nav-button {
  text-align: center;
  button {
    background-color: black;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
    padding: 10px 20px;
    cursor: pointer;
  }
}

video {
  height: 80vh;
  max-width: 100vw;
  outline: 4px solid white;
  outline-offset: -4px;
  z-index: 10;
  position: relative;
  clip-path: inset(1px 1px);
}

.scroll-arrow-container {
  height: 20vh;
  z-index: 15;
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  svg {
    cursor: pointer;
  }
}

.video-container {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.video-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 70px;
    opacity: 5%;
  }
}

.logos-container {
  width: 95%;
  margin: 60px auto;
  a {
    height: 50px !important;
    width: max-content !important;
  }
  .logo {
    width: auto;
    height: 50px;
  }
  .slick-prev:before,
  .slick-next:before {
    color: white !important;
  }
}

.slick-arrow#text {
  display: none;
}
